--<template>
  <p>{{msg}}</p>
</template>

<script>
export default {
  // 数据对象
  data() {
    return {
      msg: "正在跳转！！！",
    };
  },
  // new Vue()之后执行 vue内部给实例添加了一些属性和方法
  // data和methods之前执行
  beforeCreate() {
    window.open('./bigdatashowhtml/index.html', '_blank')
    this.$router.push('/IotPlatfrom/Buding');
    //window.location.href('./bigdatashowhtml/index.html')
    console.log("beforeCreate函数执行了");
    // 此时获取不到data中的变量值
    console.log(this.msg);
  }

};
</script>

<style scoped>
</style>